import { Component, forwardRef, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { debounceTime, distinctUntilChanged, map, Observable, OperatorFunction, Subject, takeUntil } from 'rxjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ControlValueAccessor, FormBuilder, FormGroup, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { SharedService } from '../../shared.service';
import { SharedHelperService } from '../../shared-helper.service';
import { FORM_ERROR_FN, getFormError } from '../form-field-validation';
import type { FormValidationFunction } from '../form-field-validation';
import { fadeAnimation } from 'themes/mmb-web-v1-theme/animations/fade-in-out';

interface PhoneCode{
  countryRegion:string,
  phoneNumber:string,
}

@Component({
  selector: 'mmb-web-app-common-phone-number',
  standalone: true,
  imports: [CommonModule, NgIf, NgFor, NgbModule, ReactiveFormsModule],
  templateUrl: './common-phone-number.component.html',
  styleUrls: ['./common-phone-number.component.scss'],
  animations:[fadeAnimation],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CommonPhoneNumberComponent),
      multi: true
    },
    { provide: FORM_ERROR_FN, useValue: getFormError },
  ]
})
export class CommonPhoneNumberComponent implements ControlValueAccessor, OnInit, OnDestroy {

  private _unsubscribeAll:Subject<null> = new Subject<null>();
  private _value : PhoneCode = null;
  private _countryRegionInvalid:boolean = false;
  private _phoneNumberInvalid:boolean = false;

  selectedCountry: any;
  selectedCountryCode: any;
  isPopupOpen = false;
  isInputFocused: boolean = false;
  mappedRegionNames: any;
  countryList: any[] = [];
  countryPhoneForm:FormGroup;
  disabled:boolean = false;
  userCountry: string;
  regions: any[] = [];


  
  @Input()set phoneNumberInvalid(val:boolean){
    this._phoneNumberInvalid = val;
  };

  get phoneNumberInvalid(){
    return this._phoneNumberInvalid;
  }

  @Input() set CountryRegionInvalid(val:boolean){
    this._countryRegionInvalid = val;
    if(val){
      this.countryPhoneForm.get('countryRegion').markAsTouched();
    }
  }

  get countryRegionInvalid(){
    return this._countryRegionInvalid;
  }

  removePhoneInvalidClass(){
    this._phoneNumberInvalid = false;
  }

  onChange: (value: string) => void = () => {};
  onTouch: () => void = () => {};

  get value(): PhoneCode {
    return this._value;
  }

  set value(val: PhoneCode) {
    this._value = val;
    if(this.countryPhoneForm.valid){
      const stringValue = this.mapPhoneNumbertoString();
      this.onChange(stringValue);
    }else{
      this.onChange(null);
    }
  }

  mapPhoneNumbertoString(){
    const code = this.countryPhoneForm.get('countryRegion').value;
    const number = this.countryPhoneForm.get('phoneNumber').value;
    return `${code.code}${number}`;
  }
  
  
  constructor(
    private _formBuilder : FormBuilder,
    private sharedService: SharedService,
    private sharedHelperService: SharedHelperService,
    @Inject(FORM_ERROR_FN) public validateField :FormValidationFunction
  ) {
  }

  writeValue(phoneCodeValue: PhoneCode): void {
    this.value = phoneCodeValue;
    if(!phoneCodeValue){
      this.countryPhoneForm.reset();
      this.countryPhoneForm.markAsUntouched();
      this.countryPhoneForm.markAsPristine();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    this.createCountryPhoneForm();
    this.getCountriesList();
    this.getCurrentUserCountry();
  }

  getCountriesList(){
    this.sharedService.getCountries().pipe(takeUntil(this._unsubscribeAll)).subscribe((response) => {
        this.countryList = response;
    });
  }

  getCurrentUserCountry(){
    // Get user's country
    this.sharedHelperService.userCountry$.pipe(
        takeUntil(this._unsubscribeAll),
        // Get reference types using user's country
    ).subscribe((userCountry) => {
        this.userCountry = userCountry;
        this.getReferenceTypes();
    });
  }

  private getReferenceTypes() {
    this.sharedService.referenceTypes$.pipe(takeUntil(this._unsubscribeAll)).subscribe(
        (value)=> {
            if (value) {
                // Store phone country code reference types
                this.regions = value.type_reference_phone_country_code;
                // Map regions to new format
                this.mappedRegionNames = this.mapRegions(this.regions);
                // Find selected country from mapped regions
                this.selectedCountry = this.findSelectedCountry(this.mappedRegionNames);
                // Set 'countryRegion' form control value to selected country
                this.countryPhoneForm.get('countryRegion').patchValue(this.selectedCountry);
            }
        }
    );
  }

  /**
     * This function finds the selected country from the mapped regions
     * @param {any[]} mappedRegionNames - The mapped regions to search
     * @returns {any} The selected country
     */
    private findSelectedCountry(mappedRegionNames: any[]): any {
      return mappedRegionNames.find((region) => {
          return region.country.toLowerCase().indexOf(this.userCountry?.toLowerCase()) !== -1;
      });
  }

  /**
     * This function maps the regions to a new format
     * @param {any[]} regions - The regions to map
     * @returns {any[]} The mapped regions
     */
      private mapRegions(regions: any[]): any[] {
        return regions.map((region) => {
            const [countryPhoneCode, country] = region.name.split(', ');
            let flag = '';
            if (this.countryList) {
                flag = this.countryList.find(countryItem => countryItem.name.toLowerCase() === country.toLowerCase())?.code;
            }
            return { flag, country, code: countryPhoneCode };
        });
    }

  createCountryPhoneForm(){
    this.countryPhoneForm = this._formBuilder.group({
      countryRegion:[null, Validators.required],
      phoneNumber:[null, [Validators.required, Validators.pattern('^[0-9]*$')]]
    });
  }

   /**
     * Focus event for country autocomplete
     * @param e
     */
    public onCountryFocus(e: Event): void {
      // this.selectedCountryCode = null;
      // this.selectedCountry = null;
      e.stopPropagation();
      this.isPopupOpen = true;
      setTimeout(() => {
          const inputEvent: Event = new Event('input');
          e.target.dispatchEvent(inputEvent);
      }, 0);
  }

  /**
   * Blur event for country autocomplete
   * @param e
   */
  public onCountryBlur(): void {
      this.isPopupOpen = false;
      if(typeof(this.selectedCountry) === 'string'){
          const country = this.mappedRegionNames.find(el => el.code === this.selectedCountry);
          if(country){
              this.selectedCountry = country;
              this.selectedCountryCode = country.code;
          } else{
              const closestCountry = this.mappedRegionNames.find(el => el.code.includes(this.selectedCountry));
              if(closestCountry){
                  this.selectedCountry = closestCountry;
                  this.selectedCountryCode = closestCountry.code;
              } else{
                  this.selectedCountry = null;
                  this.selectedCountryCode = null;
              }
          }
      }

      setTimeout(() => {
        this.checkFormValidityAndUpdateValue();
      }, 10);
  }

  checkFormValidityAndUpdateValue(){
    if(this.countryPhoneForm.invalid){
      this.value = null;
    }else{
      this.value = this.countryPhoneForm.value;
    }
  }

  /**
   * This function is used to extract the country code from a given string.
   * The country code is assumed to be a plus sign followed by one or more digits.
   * If the country code is found, it is returned; otherwise, an empty string is returned.
   *
   * @param {string} value - The string from which to extract the country code.
   * @returns {string} The extracted country code, or an empty string if no country code is found.
   */
  formatCountryCode = (value: any) => {
      if (this.countryList && value) {
          this.selectedCountryCode =
              this.countryList.find(countryItem => countryItem.name.toLowerCase() === value.country?.toLowerCase())?.code;
          return value.code;
      }
      return '';
  }

  currentCountriesSearch: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
        takeUntil(this._unsubscribeAll),
        debounceTime(200),
        distinctUntilChanged(),
        map((term) => {
            if (!this.mappedRegionNames) {
                return [];
            }
            return this.mappedRegionNames
                .map((region) => {
                    const { code, country, flag } = region;
                    return { country, code, flag };
                })
                .sort((a, b) => a.country.localeCompare(b.country))
                .filter((v) => v.country.toLowerCase().indexOf(term.toLowerCase()) > -1 || v.code.indexOf(term) > -1);
        }),
    );

  /**
   * This method is triggered when a country is selected from the dropdown.
   * It sets the `isInputFocused` property to `false`, causing the flag div to reappear.
   */
  onSelectCountry() {
      this.isInputFocused = false;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

}
