import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject } from "rxjs";
import { RegisterComponent } from "./register/register.component";
import { Meta, Title } from '@angular/platform-browser';
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { SharedService } from './shared.service';
import { environment } from "../../environments/environment";
import { AuthService } from "../public/auth.service";
import * as moment from 'moment/moment';

@Injectable({
    providedIn: 'root'
})
export class SharedHelperService {
    ipAddress$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    userCountry$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    siteSeoData$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);

    constructor(private modalService: NgbModal, private http: HttpClient,
        private metaService: Meta, private titleService: Title,
        private toastr: ToastrService,
        private router: Router,
        private sharedService: SharedService,
        private authService: AuthService) { }

    /**
     * Checks if user is logged in or not and then continues
     * @returns 
     */
    isUserLoggedoutRedirect() {
        if (!this.authService.isAuthenticated$.value) {
            this.router.navigate(['/login'], {
                queryParams: {
                    redirecturl: location.pathname
                }
            })
            return;
        }
    }


    /**
     * Opens register modal and keeps information for deep linked page and action
     * @param deepLinkedPage
     * @param deepLinkedAction
     */
    openRegisterModal(deepLinkedPage?: string, deepLinkedAction?: string, title?: string) {
        const modalRef = this.modalService.open(RegisterComponent, {
            centered: true,
            size: 'md'
        });
        modalRef.componentInstance.isOpenedInModal = true;
        if (title) {
            modalRef.componentInstance.title = title;
        }
        modalRef.componentInstance.deepLinkedPage = deepLinkedPage;
        modalRef.componentInstance.deepLinkedAction = deepLinkedAction;
        modalRef.componentInstance.completed.subscribe(() => {
            modalRef.dismiss();
        });
    }

    getSiteSeoData() {
        this.http.get('/assets/data/meta/pages.json').subscribe((res: any) => {
            this.siteSeoData$.next(res);
        }, (err) => { });
    }

    findSeoMetaById(id: string) {
        if (this.siteSeoData$.value) {
            return this.siteSeoData$.value.find(item => item.id === id);
        }
    }

    findSeoMetaBySlug(slug: string) {
        if (this.siteSeoData$.value) {
            return this.siteSeoData$.value.find(item => {
                item.slug = item.slug.replace('<slug>', '');
                return slug && item.slug !== '' && slug.indexOf(item.slug) !== -1;
            });
        }
    }

    setPageMeta(dynamicPageData?: any) {
        let pageData = this.findSeoMetaBySlug(location.pathname);
        if (dynamicPageData) {
            pageData = dynamicPageData;
        }
        if (pageData) {
            this.titleService.setTitle(pageData.title);

            this.metaService.updateTag({
                name: 'og:title',
                content: pageData.title
            });

            this.metaService.updateTag({
                name: 'twitter:title',
                content: pageData.title
            });

            this.metaService.updateTag({
                name: 'description',
                content: pageData.description
            });

            this.metaService.updateTag({
                name: 'og:description',
                content: pageData.description
            });

            this.metaService.updateTag({
                name: 'twitter:description',
                content: pageData.description
            });

            if (pageData['og:image']) {
                this.metaService.updateTag({
                    name: 'og:image',
                    content: pageData['og:image'].indexOf('http') !== -1 ? pageData['og:image'] : `${window.location.host}${pageData['og:image']}`
                });
            }
            if (pageData['twitter:image']) {
                this.metaService.updateTag({
                    name: 'twitter:image',
                    content: pageData['twitter:image'].indexOf('http') !== -1 ? pageData['twitter:image'] : `${window.location.host}${pageData['twitter:image']}`
                });
            }

            this.metaService.updateTag({
                name: 'keywords',
                content: pageData.keywords
            });

        }
    }

    getIPAddress() {
        let headers = {
            'Authorization': `Bearer ${environment.ipInfoAuthKey}`
        };

        this.http.get('https://ipinfo.io/json', {
            headers: headers
        }).subscribe({
            next:(res: any) => {
                this.ipAddress$.next(res.ip);
                this.sharedService.getCountries().subscribe((countries) => {
                    const country = countries.find(item => item.code === res.country.toLowerCase());
                    this.userCountry$.next(country.name);
                });
            },
            error: (err) => {
            }
        });
    }

    /**
     * Generic error handler
     * @param err
     */
    handleError(err, isRedirectEnabled?: boolean) {
        debugger;
        if (isRedirectEnabled) {
            if (err && err.status === 404) {
                this.router.navigate(['/not-found']);
            }
            if (err && err.status === 500) {
                this.router.navigate(['/internal-']);
            }
            return;
        }
        if (err && err.error.detail && err.error.detail.email) {
            this.toastr.error(err.error.detail.email[0]);
            return;
        }
        if (err && err.error.detail && err.error.detail.phone_number) {
            this.toastr.error(err.error.detail.phone_number[0]);
            return;
        }
        if (err && err.error.detail && err.error.detail.message) {
            this.toastr.error(err.error.detail.message);
            return;
        }
        if (err && err.error.detail && err.error.detail.msg) {
            this.toastr.error(err.error.detail.msg);
            return;
        }
        this.toastr.error('Oops! It seems like something went wrong on our end. We apologize for the inconvenience. Our team has been notified of the issue. Please contact our support team for further assistance.');
    }

    /**
     * Extracts the country code from the country_region field
     * @param phoneNumber
     */
    getCountryCode(phoneNumber: string): string {
        // Check if '+' is present at the start of the string
        if (phoneNumber[0] !== '+') {
            phoneNumber = '+' + phoneNumber;
        }

        const countryCodeMatch = phoneNumber.match(/\+(\d+)/);

        if (countryCodeMatch) {
            return countryCodeMatch[0]; // Use index 0 to get the entire matched string
        } else {
            return 'No country code found';
        }
    }

    /**
     * Formats the date of birth into a string in the format 'YYYY-MM-DD'.
     *
     * This function takes an object representing the date of birth, with properties for the day, month, and year.
     * If the date of birth is provided, it formats it into a string in the format 'YYYY-MM-DD' and returns it.
     * If the date of birth is not provided, it returns the original value.
     *
     * @param {Object} dateOfBirth - The date of birth to format. It should be an object with properties 'day', 'month', and 'year'.
     * @returns {string} The formatted date of birth, or the original value if the date of birth is not provided.
     */
    formatDateOfBirth(dateOfBirth: any): string {
        if (dateOfBirth) {
            return `${dateOfBirth.year}-${moment().month(dateOfBirth.month).format('MM')}-${dateOfBirth.day}`;
        }
        return dateOfBirth;
    }

    removeEmptyItems(objectBody) {
        return Object.keys(objectBody).forEach((k) =>
            (objectBody[k] === '' || objectBody[k] === null || objectBody[k] === false || objectBody[k] === undefined || objectBody[k].length===0) && delete objectBody[k]
        );
    }
}

